// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bn_k5";
export var caseStudyBackground__lineColor = "bn_kX";
export var caseStudyHead = "bn_k7";
export var caseStudyHead__imageWrapper = "bn_kV";
export var caseStudyProjectsSection = "bn_k6";
export var caseStudyQuote__bgLight = "bn_k3";
export var caseStudyQuote__bgRing = "bn_k2";
export var caseStudyVideo = "bn_lg";
export var caseStudyVideo__ring = "bn_k8";
export var caseStudy__bgDark = "bn_kT";
export var caseStudy__bgLight = "bn_kS";